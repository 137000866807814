import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyRideOptionHero } from "../../../slices/PageDataBodyRideOptionHero"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyRideOptionHero
				heading={lorem.words(2)}
				background="https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/28dec3f4-0fdd-4111-8ba0-e6b836e5a128_city-illustration.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max"
				backgroundAlt=""
				parentTitle={lorem.words(2)}
				parentURL="#"
			/>
		</CmsGuideLayout>
	)
}

export default Component
